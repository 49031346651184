import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function ThreeAm({ data }) {
  return (
    <Layout>
      <Helmet title="3 AM | Matchbox 20" />

      <h1>3 AM</h1>

      <h2>Matchbox 20</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 124</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>she says its cold outside and hands me her raincoat</p>
          <p>she's always worried about things like that</p>
          <p>she says it's all gonna end</p>
          <p>and it might as well be my fault</p>
        </Verse>
        <PreChorus>
          <p>and she only sleeps while it's raining</p>
          <p>and she screams and her voice is straining</p>
        </PreChorus>
        <Chorus>
          <p>she says baby</p>
          <p>it's 3am i must be lonely</p>
          <p>and she says baby </p>
          <p>well i cant help but be scared of it all sometimes</p>
          <p>and the rain's gonna wash away what i believe in </p>
        </Chorus>
        <Verse>
          <p>
            she's got a little bit of something, god its better than nothing
          </p>
          <p>
            and in her color portrait world she believes that she's got it all
          </p>
          <p>
            and she swears that the moon don't hang quite as high as it used to
          </p>
        </Verse>
        <PreChorus>
          <p>and she only sleeps while it's raining</p>
          <p>and she screams and her voice is straining</p>
        </PreChorus>
        <Chorus>
          <p>she says baby</p>
          <p>it's 3am i must be lonely</p>
          <p>and she says baby </p>
          <p>well i cant help but be scared of it all sometimes</p>
          <p>and the rain's gonna wash away what i believe in </p>
        </Chorus>
        <Verse>
          <p>she believes that life isn't made up of all that she used to </p>
          <p>
            and the clock on the wall has been stuck at three for days and days{" "}
          </p>
          <p>
            she thinks that happiness is a mat that sits on her doorway, yeah
          </p>
          <p>but outside it starts raining</p>
        </Verse>
        <Chorus>
          <p>she says baby</p>
          <p>it's 3am i must be lonely</p>
          <p>and she says baby </p>
          <p>well i cant help but be scared of it all sometimes</p>
          <p>and the rain's gonna wash away what i believe in </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

